<template>
  <div class="admin-reporting">
    <!-- Page header -->
    <div class="page-header">
      <h1 class="page-title">
        <!-- <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin')"
          icon="left"
        ></a-button> -->

        <admin-org-indicator></admin-org-indicator>

        Admin - Reporting
      </h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          @click.prevent="$router.push('/admin/reporting/create')"
          >Run Report</a-button
        >
      </div>
    </div>
    <!-- / Page header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Search Panel -->
    <div v-if="!isLoading" class="search-panel">
      <a-row :gutter="20" align="middle" justify="center" type="flex">
        <a-col :span="10">
          <a-form-item style="margin-bottom: 20px" label="Start Date">
            <a-date-picker
              style="width: 80%"
              size="large"
              v-model="start_date"
            />
          </a-form-item>
        </a-col>
        <a-col :span="10">
          <a-form-item style="margin-bottom: 20px" label="End Date">
            <a-date-picker style="width: 80%" size="large" v-model="end_date" />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-button
            @click.prevent="searchReports"
            class="btn-rounded"
            block
            icon="search"
            size="large"
            type="primary"
            >Search</a-button
          >
        </a-col>
      </a-row>
    </div>
    <!-- / Search Panel -->

    <!-- Is Loading Search Results -->
    <div v-if="isLoadingSearchResults" class="loader loading-search-results">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading Search Results -->

    <!-- Search Results -->
    <div
      v-if="hasSearchBeenPerformed && !isLoadingSearchResults"
      class="search-results"
    >
      <!-- No search results -->
      <a-alert
        v-if="searchResults.length == 0"
        type="info"
        message="No reports to show"
      />
      <!-- / No search results -->

      <!-- Results -->
      <a-row v-if="searchResults.length" :gutter="20" type="flex">
        <a-col
          :span="colSpan"
          v-for="(result, i) in orderedSearchResults"
          :key="i"
        >
          <reporting-list-item
            @selected="attemptDownload(result.result)"
            :tenant-id="tenantId"
            :report="result.result"
          ></reporting-list-item>
        </a-col>
      </a-row>
      <!-- / Results -->
    </div>
    <!-- / Search Results -->
  </div>
</template>

<script>
import ReportingListItem from "./Reporting/ReportingListItem.vue";
import { mapActions, mapGetters } from "vuex";
const _ = require("lodash");
import reporting from "../../api/reporting";
export default {
  name: "Reporting",

  components: { ReportingListItem },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    if (this.$route.query.reset !== 'false') {
      this.reset();
      this.searchReports();
    }
  },

  computed: {
    ...mapGetters("admin", {
      isLoading: "isLoading",
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
      myPresenceId: "myPresenceId",
    }),

    ...mapGetters("adminReporting", {
      startDate: "startDate",
      endDate: "endDate",
      hasSearchBeenPerformed: "hasSearchBeenPerformed",
      searchResults: "searchResults",
      isLoadingSearchResults: "isLoading",
    }),

    orderedSearchResults() {
      return _.orderBy(this.searchResults, "rank");
    },

    reports() {
      return _.map(this.orderedSearchResults, "result");
    },

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },

    start_date: {
      get() {
        return this.startDate;
      },
      set(val) {
        this.setStartDate(val);
        this.searchReports();
      },
    },

    end_date: {
      get() {
        return this.endDate;
      },
      set(val) {
        this.setEndDate(val);
        this.searchReports();
      },
    },
  },

  methods: {
    ...mapActions("adminReporting", {
      reset: "reset",
      setStartDate: "setStartDate",
      setEndDate: "setEndDate",
      searchReports: "searchReports",
    }),

    attemptDownload(report) {
      let vm = this;
      reporting
        .getReportUrl(this.tenantId, report.id)
        .then((r) => {
          let data = r.data;
          if (data.url) {
            window.open(data.url, "_blank");
          } else {
            vm.$message.error("Cannot download report");
          }
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error downloading report");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-reporting {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .search-panel {
    background: #fff;
    background: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .admin-reporting-list-item {
    margin-bottom: 15px;
  }

  .loading-search-results {
    margin-top: 25px;
  }

  .search-results {
    margin-top: 20px;
  }
}
</style>