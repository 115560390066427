<template>
  <div class="admin-archived-incidents">
    <!-- Page header -->
    <div class="page-header">
      <h1 class="page-title">
        <!-- <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin')"
          icon="left"
        ></a-button> -->

        <admin-org-indicator></admin-org-indicator>

        Admin - Closed Incidents
      </h1>
      <div class="actions"></div>
    </div>
    <!-- / Page header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Shortcuts -->
    <div v-if="!isLoading" class="shortcuts">
      <b>Presets: </b>
      <a-button
        class="shortcut-button"
        size="small"
        @click.prevent="todayShortcut"
        >Today</a-button
      >
      <a-button
        class="shortcut-button"
        size="small"
        @click.prevent="yesterdayShortcut"
        >Yesterday</a-button
      >
      <a-button
        class="shortcut-button"
        size="small"
        @click.prevent="thisWeekShortcut"
        >This Week</a-button
      >
      <a-button
        class="shortcut-button"
        size="small"
        @click.prevent="lastWeekShortcut"
        >Last Week</a-button
      >
    </div>
    <!-- / Shortcuts -->

    <!-- Search Panel -->
    <div v-if="!isLoading" class="search-panel">
      <a-row :gutter="20" align="middle" justify="center" type="flex">
        <a-col :span="7">
          <a-form-item style="margin-bottom: 20px" label="Start Date">
            <a-date-picker
              style="width: 80%"
              size="large"
              v-model="start_date"
            />
          </a-form-item>
        </a-col>
        <a-col :span="7">
          <a-form-item style="margin-bottom: 20px" label="End Date">
            <a-date-picker style="width: 80%" size="large" v-model="end_date" />
          </a-form-item>
        </a-col>
        <a-col :span="7">
          <a-form-item style="margin-bottom: 20px" label="Incident Type">
            <a-select
              placeholder="All Incident Types"
              show-search
              allow-clear
              option-filter-prop="children"
              v-model="incident_type"
              size="large"
              style="width: 80%"
            >
              <!-- <a-select-option :value="null">
                All Incident Types
              </a-select-option> -->
              <a-select-option
                :value="incidentType.id"
                v-for="(incidentType, i) in allIncidentTypes"
                :key="i"
              >
                {{ incidentType.displayName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="3">
          <a-button
            @click.prevent="searchIncidents"
            class="btn-rounded"
            block
            icon="search"
            size="large"
            type="primary"
            >Search</a-button
          >
        </a-col>
      </a-row>
    </div>
    <!-- / Search Panel -->

      <!-- Is Loading Search Results -->
      <div v-if="isLoadingSearchResults" class="loader loading-search-results">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading Search Results -->

    <!-- Actions -->
    <div class="actions-bar"
      v-if="hasSearchBeenPerformed && !isLoadingSearchResults && searchResults.length"
    >
    <div class="left">Showing {{ searchResults.length }} search results</div>
    <div class="right">
      <b>Actions: </b>
      <a-button class="action-button" size="small" @click.prevent="openExportToCsvModal">Export to CSV</a-button>
    </div>
  </div>
    <!-- / Actions -->

    <!-- Modals -->
    <export-archived-incidents-to-csv-modal :incidents="incidents" :tenant-id="tenantId" :my-presence-id="myPresenceId"
      :visible="showExportToCsvModal" @close-modal="closeExportToCsvmodal">
    </export-archived-incidents-to-csv-modal>
    <!-- / Modals -->

    <!-- Search Results -->
    <div
      v-if="hasSearchBeenPerformed && !isLoadingSearchResults"
      class="search-results"
    >
      <!-- No search results -->
      <a-alert
        v-if="searchResults.length == 0"
        type="info"
        message="No incidents to show"
      />
      <!-- / No search results -->

      <!-- Results -->
      <a-row v-if="searchResults.length" :gutter="20" type="flex">
        <a-col
          :span="colSpan"
          v-for="(result, i) in orderedSearchResults"
          :key="i"
        >
          <incident-list-item
            :tenant-id="tenantId"
            @selected="loadArchivedIncident(result.result)"
            :incident="result.result"
          ></incident-list-item>
        </a-col>
      </a-row>
      <!-- / Results -->
    </div>
    <!-- / Search Results -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const _ = require("lodash");
import IncidentListItem from "./Incidents/IncidentListItem.vue";
import ExportArchivedIncidentsToCsvModal from "../../components/Admin/Incidents/ExportArchivedIncidentsToCsvModal.vue";
export default {
  components: { IncidentListItem, ExportArchivedIncidentsToCsvModal },

  data() {
    return {
      windowWidth: window.innerWidth,
      showExportToCsvModal: false
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.reset();
    this.searchIncidents();
  },

  computed: {
    ...mapGetters("admin", {
      isLoading: "isLoading",
      selectedOrganisation: "selectedOrganisation",
      incidentTypes: "incidentTypes",
      allIncidentTypes: "allIncidentTypes",
      tenantId: "tenantId",
      myPresenceId: "myPresenceId",
    }),

    ...mapGetters("adminArchivedIncidents", {
      startDate: "startDate",
      endDate: "endDate",
      incidentType: "incidentType",
      hasSearchBeenPerformed: "hasSearchBeenPerformed",
      searchResults: "searchResults",
      isLoadingSearchResults: "isLoading",
    }),

    ...mapGetters("pinned", {
      pinnedIds: "pinnedIds",
    }),

    pinnedSearchResults() {
      let vm = this;
      return _.filter(this.searchResults, (searchResult) => {
        return vm.pinnedIds.includes(searchResult["result"]["id"]);
      });
    },

    unpinnedSearchResults() {
      let vm = this;
      return _.filter(this.searchResults, (searchResult) => {
        return !vm.pinnedIds.includes(searchResult["result"]["id"]);
      });
    },

    orderedSearchResults() {
      let pinnedSearchResultsOrdered = _.orderBy(
        this.pinnedSearchResults,
        ["result.created", "rank"],
        ["desc", "desc"]
      );

      let unpinnedSearchResultsOrdered = _.orderBy(
        this.unpinnedSearchResults,
        ["result.created", "rank"],
        ["desc", "desc"]
      );

      return _.flattenDeep([
        pinnedSearchResultsOrdered,
        unpinnedSearchResultsOrdered,
      ]);
    },

    incidents() {
      return _.map(this.orderedSearchResults, "result");
    },

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },

    start_date: {
      get() {
        return this.startDate;
      },
      set(val) {
        this.setStartDate(val);
        this.searchIncidents();
      },
    },

    end_date: {
      get() {
        return this.endDate;
      },
      set(val) {
        this.setEndDate(val);
        this.searchIncidents();
      },
    },

    incident_type: {
      get() {
        return this.incidentType;
      },
      set(val) {
        this.setIncidentType(val);
        this.searchIncidents();
      },
    },
  },

  methods: {
    ...mapActions("adminArchivedIncidents", {
      reset: "reset",
      setStartDate: "setStartDate",
      setEndDate: "setEndDate",
      setIncidentType: "setIncidentType",
      searchIncidents: "searchIncidents",
    }),

    ...mapActions("adminIncidentViewer", {
      setArchivedIncident: "setArchivedIncident",
    }),

    getIncidentUrl(incident) {
      return "/incidents/" + this.tenantId + "/" + incident.id + "?admin=true";
    },

    navigateToIncident(incident) {
      this.$router.push(this.getIncidentUrl(incident));
    },

    todayShortcut() {
      this.setStartDate(window.moment());
      this.setEndDate(window.moment());
      this.searchIncidents();
    },

    yesterdayShortcut() {
      let yesterday = window.moment().subtract(1, "day");
      this.setStartDate(yesterday);
      this.setEndDate(yesterday);
      this.searchIncidents();
    },

    thisWeekShortcut() {
      let beginningOfWeek = window.moment().startOf("week");
      let endOfWeek = window.moment().endOf("week");
      this.setStartDate(beginningOfWeek);
      this.setEndDate(endOfWeek);
      this.searchIncidents();
    },

    lastWeekShortcut() {
      let beginningOfWeek = window.moment().startOf("week");
      let endOfWeek = window.moment().endOf("week");
      this.setStartDate(beginningOfWeek.subtract(1, "week"));
      this.setEndDate(endOfWeek.subtract(1, "week"));
      this.searchIncidents();
    },

    loadArchivedIncident(incident) {
      this.setArchivedIncident({
        incidentId: incident.id,
        tenantId: this.tenantId,
        incident: incident,
      });
      this.navigateToIncident(incident);
    },

    openExportToCsvModal() {
      this.showExportToCsvModal = true;
    },

    closeExportToCsvmodal() {
      this.showExportToCsvModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.admin-archived-incidents {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .search-panel {
    background: #fff;
    background: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .admin-incident-list-item {
    margin-bottom: 15px;
  }

  .loading-search-results {
    margin-top: 25px;
  }

  .search-results {
    margin-top: 20px;
  }

  .shortcuts {
    padding-bottom: 15px;

    .shortcut-button {
      margin-left: 7px;
    }
  }

  .actions-bar {
    padding-top: 20px;

    display: flex;

    .left {
      flex-grow: 1;
      font-weight: 500;
    }

    .right {
      flex-shrink: 1;
    }

    .action-button {
      margin-left: 7px;
    }
  }
}
</style>